import { WizardOptions } from '../../../types/wizard/wizard-options';
import {
    AddItemSearchCategoryComponent
} from './select-category/add-item-search-category.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { AddItemOverviewComponent } from './overview/add-item-overview.component';
import { inject } from '@angular/core';
import { AddItemGQL, CategoryByIdGQL, CompanyByIdGQL } from '../../../../../graphql/generated';
import { AddItemPropertiesComponent } from './item-properties/add-item-properties.component';
import { map, tap } from 'rxjs';
import { AddItemWizardContext } from './add-item-wizard-context.service';

interface AddItemWizardOptions {
    companyId: string;
    categoryId?: string;

    onCompleted?(itemId: string): void;
}

export function addItemWizard(options: AddItemWizardOptions): WizardOptions<AddItemWizardContext> {
    return {
        title: 'Item toevoegen',
        context: AddItemWizardContext,
        initialize(context, setStep) {
            context.set('companyId', options.companyId);
            if (options.categoryId) {
                context.set('categoryId', options.categoryId);
                setStep(1);
            }
        },
        steps: [
            {
                component: AddItemSearchCategoryComponent,
                isComplete(context): boolean {
                    return !!context.value('categoryId');
                }
            },
            {
                component: ItemDetailsComponent,
                isComplete(context: AddItemWizardContext): boolean {
                    return context.exists([
                        'location'
                    ]);
                }
            },
            {
                component: AddItemPropertiesComponent,
                disabled(context: AddItemWizardContext) {
                    const categoryByIdGQL = inject(CategoryByIdGQL);
                    return categoryByIdGQL.watch({
                        id: context.value('categoryId')
                    })
                        .valueChanges
                        .pipe(
                            map(({ data }) => data?.categoryById?.properties.length === 0)
                        )
                },
                isComplete(context: AddItemWizardContext): boolean {
                    return true;
                }
            },
            {
                component: AddItemOverviewComponent,
                isComplete(context: AddItemWizardContext): boolean {
                    return true;
                }
            }
        ],
        onClose: (context) => {
            context.reset();
        },
        complete: (context) => {
            const addItemGQL = inject(AddItemGQL);

            return addItemGQL.mutate({
                input: {
                    companyId: context.value('companyId'),
                    categoryId: context.value('categoryId'),
                    equipmentIdentifier: context.value('equipmentIdentifier'),
                    description: context.value('description'),
                    location: context.value('location')!,
                    properties: context.value('properties'),
                    images: []
                }

            }, {
                refetchQueries: [
                    {
                        query: inject(CompanyByIdGQL).document,
                        variables: { id: context.value('companyId') }
                    }
                ]
            }).pipe(
                tap({ next: () => context.reset() }),
                tap({ next: (result) => options.onCompleted?.(result?.data?.addItem?.itemId) })
            )
        }
    }
}
