import { Component, OnInit } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { AddressSearchDropdownComponent } from "../../../../core/components/inputs/address-search-dropdown/address-search-dropdown.component";
import { CategoryByIdGQL, LocationInput } from "../../../../../../graphql/generated";
import { AddItemWizardContext } from "../add-item-wizard-context.service";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, of, switchMap } from 'rxjs';
import { LoadingStateComponent } from "../../../../core/loading-state/loading-state.component";

@Component({
    selector: 'app-item-details',
    standalone: true,
    imports: [
        FormsModule,
        AddressSearchDropdownComponent,
        LoadingStateComponent
    ],
    templateUrl: './item-details.component.html',
})
export class ItemDetailsComponent implements OnInit {

    // ----------[ Computed ]----------

    private readonly categoryId = this.context.select('categoryId');
    private readonly categoryId$ = toObservable(this.categoryId);

    public readonly category = toSignal(this.categoryId$.pipe(
        switchMap(id => id ? this.categoryByIdGQL.watch({ id }).valueChanges : of(null)),
        map(result => result?.data?.categoryById)
    ))

    // ----------[ State ]----------
    equipmentIdentifier: string | null = null;
    description: string | null = null;
    location: LocationInput | null = null;
    images: string | null = null;
    initialLocationSearch: string | null = null;

    // ----------[ Dependencies ]----------

    constructor(
        private readonly categoryByIdGQL: CategoryByIdGQL,
        private readonly context: AddItemWizardContext,
    ) {}

    ngOnInit() {
        // Initialize component properties with stored values
        this.equipmentIdentifier = this.context.value('equipmentIdentifier');
        this.description = this.context.value('description');
        this.location = this.context.value('location');
        this.images = this.context.value('images');

        // Set initial location search if location exists
        if (this.location) {
            this.initialLocationSearch = this.location.properties.formattedAddress;
        }
    }

    // ----------[ Template Methods ]----------

    onAddressSelect(selectedLocation: LocationInput | null) {
        this.location = selectedLocation;
        this.updateContext();
    }

    updateContext() {
        this.context.setState({
            equipmentIdentifier: this.equipmentIdentifier,
            description: this.description,
            location: this.location,
            images: this.images
        });
    }
}
