@if (category(); as category) {
    <div class="item-properties-form grid grid-cols-1 auto-rows-auto gap-6 text-sm">
        @for (property of category.properties; track property) {
            <div class="col-span-1 item-properties-form">
                <div class="font-bold text-gray-900">
                    {{ property.name }}
                </div>
                <div class="mt-2">
                    @switch (property.__typename) {
                        @case ('BooleanProperty') {
                            <label class="flex items-center font-medium text-gray-900">
                                <input
                                    type="checkbox"
                                    [checked]="booleanPropertyValue($any(property))"
                                    (change)="onChangeBooleanProperty($any(property), $event)"
                                    class="size-4 mr-2 rounded-lg accent-red-500 border-red-600 text-red-600 focus:ring-red-600 bg-red-600 checked:bg-red-600 pointer-events-none"
                                />
                                <ng-container i18n="@@component.item-properties-form.yes">
                                    Ja
                                </ng-container>
                            </label>
                        }
                        @case ('PredefinedValueProperty') {
                            @if (property.inputOptions.isRequired && !isAnyPredefinedValuePropertySelected($any(property))) {
                                <div class="text-red-600 pb-2">
                                    <ng-container i18n="@@component.item-properties-form.required">
                                        Verplicht
                                    </ng-container>
                                </div>
                            }
                            <div class="space-y-2">
                                @for (option of property.values; track option) {
                                    <div>
                                        <label class="flex items-center font-medium text-gray-900">
                                            <input
                                                [type]="property.inputOptions.isMultiple ? 'checkbox' : 'radio'"
                                                [value]="option.id"
                                                [checked]="isPredefinedValuePropertySelected($any(property), $any(option))"
                                                (change)="onSelectPredefinedValueProperty($any(property), $any(option))"
                                                class="size-4 mr-2 rounded-lg accent-red-500 border-red-600 text-red-600 focus:ring-red-600 bg-red-600 checked:bg-red-600 pointer-events-none"
                                            />
                                            {{ option.name }}
                                        </label>
                                    </div>
                                }
                            </div>
                        }
                        @case ('NumericProperty') {
                            <div class="flex flex-col space-y-1">
                                <p-inputNumber
                                    [ngModel]="numericPropertyValue($any(property))"
                                    (ngModelChange)="onChangeNumericProperty($any(property), $event)"
                                    [min]="property.inputOptions.minValue!"
                                    [max]="property.inputOptions.maxValue!"
                                    [step]="property.searchOptions.step"
                                    [minFractionDigits]="0"
                                    [maxFractionDigits]="2"
                                    locale="nl-NL"
                                    [showButtons]="true"
                                    [inputId]="'numeric-property-' + property.id"
                                    [placeholder]="property.name.toLowerCase() + ' in ' + '(' + property.unit + ')'"
                                    [required]="true"

                                    inputStyleClass="w-full py-2 px-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    styleClass="w-full"
                                >
                                </p-inputNumber>
                                @if (!isNumericPropertySet($any(property))) {
                                    <span class="text-red-500 text-xs">Dit veld is verplicht</span>
                                }
                                <span class="text-xs text-gray-500">
                                    Min: {{ property.inputOptions.minValue }} ({{property.unit}}) | Max: {{ property.inputOptions.maxValue  }} ({{property.unit}})
                                </span>
                            </div>
                        }
                    }
                </div>
            </div>
        }
    </div>
}
