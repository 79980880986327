<app-loading-state [loading]="!category()">
    <div class="px-6 pt-4 pb-6">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Informatie</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500">Vertel ons en andere meisters meer over jouw machine en/of medewerker.<br/>Deze informatie wordt openbaar weergegeven op de detailpagina.</p>
        <div class="space-y-6">
            <div class="space-y-2">
                <label for="equipmentNumber" class="block text-sm font-medium text-gray-700 mt-3">Materieelnummer</label>
                <input
                    id="equipmentNumber"
                    type="text"
                    class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    [(ngModel)]="equipmentIdentifier"
                    (ngModelChange)="updateContext()"
                >
                <small class="text-xs text-gray-500">Intern identificatienummer. Dit nummer is niet openbaar zichtbaar.</small>
            </div>

            <div class="space-y-2">
                <label for="description" class="block text-sm font-medium text-gray-700 mt-3">Beschrijving</label>
                <textarea
                    [(ngModel)]="description"
                    (ngModelChange)="updateContext()"
                    id="description"
                    rows="4"
                    class="w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6">
                </textarea>
            </div>

            <div class="space-y-2">
                <label for="defaultLocation" class="block text-sm font-medium text-gray-700 mt-3 mb-2">
                    Standplaats
                </label>
                <app-address-search-dropdown
                    id="defaultLocation"
                    (selected)="onAddressSelect($event)"
                    [initial]="initialLocationSearch"
                    class="w-64"
                />
            </div>
        </div>
    </div>
</app-loading-state>
