import { Injectable } from '@angular/core';
import { Store } from "../../../core/services/store.service";
import { Category, LocationInput, PropertyValueInput } from '../../../../../graphql/generated';

interface AddItemWizardState {
    companyId: string | null;
    categoryId: string | null;
    category:Category | null;
    equipmentIdentifier: string | null;
    description: string | null;
    location: LocationInput | null;
    images: string | null;
    properties: PropertyValueInput[];
}

@Injectable({
    providedIn: 'root'
})
export class AddItemWizardContext extends Store<AddItemWizardState> {

    constructor() {
        super();

        this.reset();
    }

    reset() {
        this.setState({
            categoryId: null,
            category: null,
            equipmentIdentifier: null,
            description: null,
            location: null,
            images: null,
            properties: []
        });
    }

}
