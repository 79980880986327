import { Component, computed, input } from '@angular/core';
import { NgIcon } from "@ng-icons/core";
import { CategoryByIdGQL } from "../../../../../graphql/generated";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, switchMap } from "rxjs";
import { WizardService } from "../../../core/services/wizard.service";
import { addItemWizard } from "../../wizards/add-item/add-item.wizard";
import { Router } from '@angular/router';

@Component({
    selector: 'company-add-item-card',
    standalone: true,
    imports: [
        NgIcon,
    ],
    templateUrl: './add-item-card.component.html',
})
export class AddItemCardComponent {

    // ----------[ Inputs ]----------

    public readonly companyId = input.required<string>();

    public readonly categoryId = input.required<string>();
    protected readonly categoryId$ = toObservable(this.categoryId);

    // ----------[ Computed ]----------

    protected readonly category = toSignal(this.categoryId$.pipe(
        switchMap(categoryId => this.categoryById.watch({ id: categoryId }).valueChanges),
        map(result => result.data.categoryById)
    ));

    protected readonly categoryNameSentenceCase = computed(() => {
        const name = this.category()?.name;
        return name ? name.charAt(0).toLowerCase() + name.slice(1) : "";
    });

    // ----------[ Dependencies ]----------

    constructor(
        private readonly categoryById: CategoryByIdGQL,
        private readonly wizardService: WizardService,
        private readonly router:Router,
    ) {}

    // ----------[ Template Methods ]----------

    protected openAddItemWizard() {
        this.wizardService.open(
            addItemWizard({
                companyId: this.companyId(),
                categoryId: this.categoryId(),
                onCompleted: (itemId: string) => {
                    this.router.navigate(['bedrijf/beheer/inventaris', itemId], {
                        queryParams: { tab: 'media' },
                        queryParamsHandling: 'merge'
                    });
                }
            })
        );
    }
}
